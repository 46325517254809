var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", [
    _c("iframe", {
      attrs: {
        title: "Tablero General LatinAD",
        width: "100%",
        height: "800",
        src: "https://app.powerbi.com/reportEmbed?reportId=b79a8726-6424-49be-91d6-9d35e7018a96&autoAuth=true&ctid=924c8c24-3938-4c40-9883-e77fe87a259b",
        frameborder: "0",
        allowfullscreen: "true",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }