<template>
    <vs-select
        class="w-full"
        :clearable="false"
        @input="setCurrencies"
        v-model="currencySelected"
        :disabled="loading"
    >
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in currencies" />
        <span slot="no-options">Nada que mostrar.</span>
    </vs-select>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        props: {
            label: {
                type: String,
                default: 'Seleccionar moneda'
            }
        },
        data () {
            return {
                //default value
                currencies: [{value: 'all', text: 'Todas'}],
                currencySelected: 'all',
                loading: false
            }
        },
        methods: {
            setCurrencies () {
                this.params.handleFilter({
                    [this.params.filterName || 'currency']: this.currencySelected === 'all' ? null : this.currencySelected,
                });
            },
        },
        mounted () {
            this.currencies = [{value: 'all', text: 'Todas'}, ...this.params.currencies]
                  // If the filter is not applied, set the selected option to null
            const values = this.params.getValues()

            // If the filter is applied, set the selected option to the value of the filter
            if (values[this.params.filterName || 'currency'] !== null && values[this.params.filterName || 'currency'] !== undefined) {
                this.currencySelected = values[this.params.filterName || 'currency']
            }
        }
    })
</script>
