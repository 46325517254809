<template>
    <div>
      <SSRAgTable
      v-on="$listeners"
      :frameworkComponents="frameworkComponents"
        :columns="columnDefsList"
        :data="rowData"
        :totalRows="totalRows"
        :totalPages="totalPages"
        :isLoading="isLoading"
        @fetchData="getGridData"
        @updateSelectedItems="onSelectedItems"
        :columnFilters="columnFilters"
        @searchQuery="searchQuery = $event"
      />

      <!-- Popup Documentation  -->
      <!-- Documentation popup -->
      <show-docs-popup
        :poModalActive.sync="poModalActive"
        :poModalData="poModalData"
        title="Cobros" 
        variant="charges"
        @updatePurchaseOrders="updatePurchaseOrders"
      />
  
    </div>
</template>
    
<style>
    /* Add !important to display: none added by v-show directive */
    *[style*="display: none"] {
      display: none !important;
    }
</style>
    
<script>
    import { LicenseManager } from 'ag-grid-enterprise'
    LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
    import SSRAgTable from '../components/ag-table/SSRAgTable.vue'
    import appConfig from '@/../appConfig.js'
    import { sortAlphabetize } from '@/utils/utils.js'


    import ShowDocsPopup from '../components/popups/show-docs-popup/ShowDocsPupup.vue'
    
    import { columnDefs } from '../utils/tables/charges/charges'
    import BillingAmountRenderer from '../components/table-cells/BillingAmountRenderer.vue'
    import CurrencyFilter from '../components/filters/columnFilters/CurrencyFilter.vue'
    import CountryFilter from '../components/filters/columnFilters/CountryFilter.vue'
    
    export default {
        name: 'charges-table',
        components: {
            SSRAgTable,
            ShowDocsPopup
        },
        props: {
            filters: {
                type: Object,
                required: true
            },
        },
        data () {
            return {
                // Charges grid options
                searchQuery: '',
                columnDefsList: null, 
                currentPage: 1,
                totalRows: 0,
                totalPages: 1,
                columnFilters: {},
                rowData: [],
                frameworkComponents: null,
                //Items selected
                selectedRows: null,
                countSelected: 0,
                //loading flag
                isLoading: false,
                //Documentation
                poModalActive: false,
                poModalData: null,
            }
        },
        watch: {},
        computed: {
            columnDefs() {
                return this.columnDefsList; // Devolver la referencia fija
            }
        },
        methods: {
        getGridData ({ page = 1, pageSize = 20, name = this.searchQuery, columnFilters = this.columnFilters }) {
            this.isLoading = true;
            this.countSelected = 0
            let {holdings, companies} = this.filters
          
            const holdingArray = holdings.length > 0 ? (holdings[0].value === 'all' ? null : holdings.map(holding => holding.value)) : null
            const companyArray = companies.length > 0 ? (companies[0].value === 'all' ? null : companies.map(company => company.value)) : null

            this.$http.get(`${appConfig.apiUrl}/panel/charges`, {
                params: {
                    page: page,
                    per_page: pageSize,
                    campaign_name: name,
                    ...columnFilters,
                    holdings: holdingArray,
                    companies: companyArray,
                }
            })
            .then((response) => {
                const { pagination, data } = response.data
                const { total, last_page, current_page } = pagination
                
                this.currentPage = current_page
                this.totalRows = total
                this.rowData = data
                this.totalPages = last_page
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.isLoading = false
            })
          // .then(() => {
          //   this.isLoading = false
          // })
        },
        // Method to open the purchase order modal
        openPoModal ({campaignId, companyId}, purchaseOrder) {
            this.poModalActive = true
            this.poModalData = {campaignId, companyId, purchaseOrder}
        },
        onSelectedItems (selectedItems) {
            this.selectedRows = selectedItems
            this.countSelected = selectedItems.length
        },
        async getCurrencies () {
            this.loading = true
            try {
              const response = await this.$http.get(`${appConfig.apiUrl}/countries`)
              let currencies = response.data.map((country) => ({
                text: country.default_currency.code,
                value: country.default_currency.code
              }))
              currencies = [...new Set(currencies.map(c => JSON.stringify(c)))].map(str => JSON.parse(str))
              currencies = sortAlphabetize(currencies, 'text')

              return currencies
            } catch (error) {
              console.log(error)
            } finally {
              this.loading = false
            }
        },
        updatePurchaseOrders({campaignId, companyId, newPurchaseOrder}) {
            const newData = this.rowData.map(row => {
                if(row.campaign.id === campaignId && row.company.id === companyId) {
                return {
                    ...row,
                    company: {
                    ...row.company,
                    purchase_order: newPurchaseOrder
                    }
                }
                }
                return row;
            });
            this.rowData = newData;
        },
        handleFilter(filter) {
            this.columnFilters = {
                ...this.columnFilters,
                ...filter
            }
        },
        getValues() {
            return this.columnFilters
        }
        },
        beforeMount () {
            this.frameworkComponents = {
                billingAmountRenderer: BillingAmountRenderer,
                currencyFilter: CurrencyFilter,
                countryFilter: CountryFilter,
            }
        },
        
        async mounted () {
            const currencies = await this.getCurrencies()

            this.columnDefsList = columnDefs({
                handleFilter: this.handleFilter.bind(this),
                openPoModal: this.openPoModal.bind(this),
                getValues: this.getValues.bind(this),
                filterOptions: {
                    currencies
                }
            });
        },
    }
</script>