export const companyNameGetter = params => {
  const {data} = params
  const {company} = data

  if (!company) return '-'

  return company.name || '-'
}

export const holdingNameGetter = params => {
  const {data} = params
  const {company} = data
  const {holding} = company

  if (!company || !holding) return '-'

  return `${holding.name || '-'} (${holding.id || '-'})`
}

export const legalNameGetter = (params) => {
  const billingInfo = params.data.company.billing_information

  if (billingInfo) {
    if (Array.isArray(billingInfo) && billingInfo.length === 0) return '-'
    return billingInfo.fantasy_name
  }

  return '-'
}

export const expirationChargeDateGetter = params => {
  const {charge} = params.data
  if (!charge) return null

  return charge.date
}

export const chargeDelayGetter = params => {
  if (!params.data.charge || !params.data.charge.date) return '-'

  const paymentDate = new Date(params.data.charge.date)
  const today = new Date()

  const diference = today - paymentDate

  const daysUntilExpiration = Math.floor(diference / (1000 * 60 * 60 * 24));

  return daysUntilExpiration > 0 ? `${daysUntilExpiration} días de atraso` : `Faltan ${daysUntilExpiration * -1} días para el pago`
}

export const countryGetter = params => {
  const {data} = params
  const {company} = data
  const {country} = company

  if (!company || !country) return '-'

  return country.name
}

export const invoiceIssuingCountryGetter = params => {
    const {data} = params
    if (data.campaign.invoice_issuing_country) {
        return data.campaign.invoice_issuing_country.name
    }
    if (data.billing_information_address_country) {
        return data.billing_information_address_country
    }
    return '-'
}

export const amountReceivableWithTaxGetter = params => {
  const {data} = params
  const {total_price_with_commission, tax} = data

  if (!total_price_with_commission || !tax) return null

  return parseFloat(parseFloat(total_price_with_commission) * (1 + (parseFloat(tax) / 100))).toFixed(2)
}
