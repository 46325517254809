<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div>
      <vx-card class="campaigns-container">
        <vx-card class="mb-2 campaign-filters" title="Filtros">
          <vs-row vs-align="flex-end" vs-type="flex" vs-justify="space-between" vs-w="12">
            <vs-col vs-w="11">
              <vs-row class="gap-4 items-end">
                <vs-col vs-w="2">
                  <holding-select @value="filters.holdings = $event" @companies="companies = $event" @clearCompanies="clearCompanies"/>
                </vs-col>
                <vs-col vs-w="2">
                  <companies-select 
                    @value="filters.companies = $event" 
                    :companies="companies" 
                    ref="companiesSelect"
                  />
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col vs-w="1">
              <vs-col vs-w="12">
                <vs-button @click="searchData" class="mr-3 mb-2">Buscar</vs-button>
              </vs-col>
            </vs-col>
          </vs-row>
        </vx-card>
        <!-- CHARGES TABLE -->
        <charges-table
          :filters="filters"
          ref="campaignsCharges"
        ></charges-table>
      </vx-card>
    </div>
  </template>
  
  <style>
  .ag-header-group-cell.column-group-1 {
    background-color: #b39ddb;
  }
  .ag-header-group-cell.column-group-1:hover {
    background-color: #9575cd !important;
  }
  .ag-header-group-cell.column-group-2 {
    background-color: #ef9a9a;
  }
  .ag-header-group-cell.column-group-2:hover {
    background-color: #e57373 !important;
  }
  .ag-header-group-cell.column-group-3 {
    background-color: #a5d6a7;
  }
  .ag-header-group-cell.column-group-3:hover {
    background-color: #81c784 !important;
  }
  
  .campaign-filters .vx-card__header {
    padding: 10px 10px 0px 15px !important;
  }
  
  .table-selector .vs-tabs--content {
    padding: 1px;
  }
  
  .table-selector .vs-tabs--btn {
    padding: 7px!important;
  }
  
  .campaigns-container .vx-card__body {
    padding:  15px !important;
  }
  
  .font-12 * {
    font-size: 12px !important;
  }
  
  .height-32 {
    height: 32px !important;
  }
  
  /* Add !important to display: none added by v-show directive */
  *[style*='display: none'] {
    display: none !important;
  }
  </style>
  
  <script>
    import { LicenseManager } from 'ag-grid-enterprise'
    LicenseManager.setLicenseKey(
        'CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491'
    )
    import { AgGridVue } from 'ag-grid-vue'
    import ChargesTable from './ChargesTable.vue'
    import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import HoldingSelect from '../components/holding-select/HoldingSelect.vue'
    import CompaniesSelect from '../components/companies-select/CompaniesSelect.vue'
  
    import './css/campaings.scss'
    import 'flatpickr/dist/flatpickr.css'
    import 'flatpickr/dist/plugins/monthSelect/style.css'
    import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
  
    export default {
        components: {
        AgGridVue,
        flatPickr,
        ChargesTable,
        vSelect,
        HoldingSelect,
        CompaniesSelect,
        },
        data() {
        return {
            companies: [],
            filters: {
                holdings: [],
                companies: [],
            },
        }
        },
        computed: {},
        methods: {
            searchData() {
                this.$refs.campaignsCharges.getGridData({page: 1})
            },
            clearCompanies() {
                this.$refs.companiesSelect.clearCompanies()
            },
        },
    }
</script>
    