import {
  currencyFormatter,
  dateFormatter,
} from '../../strings'
import { amountReceivableWithTaxGetter, chargeDelayGetter, companyNameGetter, countryGetter, expirationChargeDateGetter, holdingNameGetter, invoiceIssuingCountryGetter, legalNameGetter } from './charges.getters'

const cellStyles = {
  billingDate: { backgroundColor: '#ab85ad', color: '#fff' },
  amountReceivableWithoutTax: { backgroundColor: '#70A8A6', color: '#f5f5f5' },
  amountReceivableWithTax: { backgroundColor: '#F07775', color: '#fff' }
}

const getFilesData = (params) => {
  const {data} = params
  const {company, campaign} = data
  const {id} = campaign
  const {purchase_order} = company

  if(purchase_order){
    return {
      campaign_id: id,
      files: purchase_order.map((fileData) => fileData)
    }
  }

  return {
    campaign_id: id,
    files: []
  }
}

export const columnDefs = ({openPoModal, handleFilter, filterOptions, getValues}) => [
  {
    headerName: 'Campaña',
    headerClass: 'column-group-1',
    children: [
      {
        headerName: 'ID',
        field: 'campaign.id',
        width: 150,
        filter: false,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerCheckboxSelection: true,
        pinned: 'left'
      },
    ]
  },
  {
    headerName: 'Empresa',
    headerClass: 'column-group-2', 
    children: [
      {
        headerName: 'Holding agencia',
        valueGetter: holdingNameGetter,
        filter: false,
        width: 200
      },
      {
        headerName: 'Anunciante',
        valueGetter: companyNameGetter,
        filter: false,
        width: 200
      },
      //From ODOO
      {
        headerName: 'Razón social',
        valueGetter: legalNameGetter,
        filter: false,
        width: 200
      },
      {
        headerName: 'País',
        valueGetter: countryGetter,
        filter: true,
        floatingFilterComponentParams: {
          handleFilter,
          suppressFilterButton: true,
          getValues,
        },
        floatingFilterComponent: "countryFilter",
        width: 200
      },
    ]
  },
  {
    headerName: 'Operación',
    headerClass: 'column-group-3',
    children: [
      {
        headerName: 'Cobrado',
        valueGetter: getFilesData,
        cellRenderer: 'billingAmountRenderer',
        cellRendererParams: {
          labelChip: 'Cobrado',
          field: 'collected_at'
        },
        filter: false,
        width: 120,
        cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
        pinned: 'left',
      },
      {
        headerName: 'Nombre Campaña',
        field: 'campaign.name',
        filter: false,
        width: 300,
        wrapText: true
      },
      {
        headerName: 'Fecha de vencimiento',
        valueGetter: expirationChargeDateGetter,
        valueFormatter: dateFormatter,
        filter: false,
        width: 200,
        wrapText: true,
        headerClass: 'column-group-1'
      },
      {
        headerName: 'País de facturación',
        valueGetter: invoiceIssuingCountryGetter,
        filter: true,
        floatingFilterComponentParams: {
          handleFilter,
          suppressFilterButton: true,
          getValues,
          filterName: 'invoice_issuing_countries'
        },
        floatingFilterComponent: "countryFilter",
        width: 200
      },
      {
        headerName: 'Atraso',
        valueGetter: chargeDelayGetter,
        filter: false,
        width: 200,
        wrapText: true,
        headerClass: 'column-group-1'
      },
      {
        headerName: 'Divisa',
        field: 'campaign.currency',
        filter: true,
        floatingFilterComponentParams: {
          handleFilter,
          filterName: 'currency',
          suppressFilterButton: true,
          getValues,
          currencies: filterOptions.currencies,
        },
        floatingFilterComponent: "currencyFilter",
        width: 200,
      },
      {
        headerName: 'Monto a cobrar S/I',
        field: 'charge.amount_without_tax',
        valueFormatter: currencyFormatter,
        cellStyle: cellStyles.amountReceivableWithoutTax,
        filter: false,
        width: 280
      },
      {
        headerName: 'Monto a cobrar C/I',
        valueGetter: amountReceivableWithTaxGetter,
        valueFormatter: currencyFormatter,
        filter: false,
        cellStyle: cellStyles.amountReceivableWithTax,
        width: 285
      },
      {
        headerName: 'Documentacion',
        pinned: 'left',
        filter: false,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          const {data} = params
          const {company, campaign} = data
          const {purchase_order, id: companyId} = company
          const {id: campaignId} = campaign
          
          const button = document.createElement('a')
          button.innerText = 'Ver'
          button.style.cursor = 'pointer'
          button.addEventListener('click', (event) => {
            event.stopPropagation()
            const fileData = purchase_order ? purchase_order.map((fileData) => fileData) : []
            
            openPoModal({campaignId, companyId}, fileData)
          })
          return button
      }
      },
    ]
  },  
]
